

<template>
  <div @load.once="refreshPage()" id="dashboard-analytics">
    <!--   Essential message-->
    <!-- <div>
      <EssentialMessage/>
    </div> -->
    <!--  end Essential message-->
    <!--StarterContainer area-->
    <div>
      <!-- <h3 class="text-white pl-0 md:pl-5 mb-5 text-base md:text-2xl d-none d-md-block">داشبورد {{ activeUserInfo.name }}</h3> -->

      <!-- <StarterContainer /> -->
    </div>
    <!--end StarterContainer area-->


    <!--ItemsCards area-->
    <div style="flex-direction: column" class="w-full md:flex-col lg:flex-row   xl:flex-row flex">
      <FastAccess />

    </div>
    <!--end ItemsCards area-->


  </div>
</template>

<script>
// import VxCard from '../components/vx-card/VxCard.vue'
// import NotificationCard from './components/Notification/NotificationCard.vue'
// import StarterContainer from './components/StarterContainer/StarterContainer.vue'
// import ProductCard from './components/ItemsCards/ProductCard.vue'
// import EssentialMessage from './components/Notification/EssentialMessage.vue'
import FastAccess from './FastAccess.vue'
export default {
  components: {
    FastAccess
  },

  data () {
    return {
      fields: [
        {
          key: 'last_name',
          sortable: true
        },
        {
          key: 'first_name',
          sortable: false
        },
        {
          key: 'age',
          label: 'Person age',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          variant: 'info'
        }
      ],
      items: {},
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      userName: '',

      dispatchedOrders: []
    }
  },
  computed:{
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },

  methods:{
    contest () {
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.6
      })
      this.$http.post('/contest/subscribe').then((result) => {
        this.$vs.loading.close('#div-with-loading > .con-vs-loading')
        this.$vs.notify({text: result.data.message})
      }).catch((err) => {
        this.$vs.notify({
          text : err.response.data.message
        })
      })
    },
    getinfo () {
      const header = localStorage.getItem('accessToken')
      this.$store.dispatch('auth/fetchUserData', header)
    },

    checkLogin () {

      if (localStorage.getItem('accessToken')) {
        // const payload = localStorage.getItem('accessToken')

      } else {

        // this.$router.push({path: '/pages/login'})
        // this.$vs.notify({
        //   title: 'Login Attempt',
        //   text: 'ابتدا وارد شوید!',
        //   iconPack: 'feather',
        //   icon: 'icon-alert-circle',
        //   color: 'primary'
        // })

      }


    },

    servicesUsage () {
      this.$http.get('/user/consume').then((result) => {
        // console.log(result)
        const data = result.data.params
        this.items = data
      }).catch((err) => {
        console.log(err)
      })
    },
    async addInstance () {
      const insData = {
        vm_title: 'haio-competition',
        vm_hostname: 'haio-competition',
        template_id: '27',
        vm_vcpu: '2',
        vm_ram: '2',
        vm_hard: '35',
        template_version: 'latest'
      }
      // console.log(insData)
      this.$vs.loading()
      try {
        const res = await this.$http.post('/vira/cloud/vm/create/simple', insData)
        this.$vs.notify({
          color: 'success',
          title: res.data.message,
          text: ' وی ام شما برای مسابقه در حال ایجاد است اطلاعات به ایمیل شما ارسال میشود'
        })

      } catch (err) {

        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      }
      this.$vs.loading.close()
      this.$router.push('/apps/cloudlist/')
    }

  },


  created () {

    this.checkLogin()
    setTimeout(this.getinfo, 2000)

  }

}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }

.parent-wrapper {
  position: relative;
  width: 400px;
  height: 270px;
  margin: 40px auto 0;
  background-image: url('http://www.imgbase.info/images/safe-wallpapers/digital_art/1_miscellaneous_digital_art/41750_1_miscellaneous_digital_art_simple_dark_shapes.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: -600%;
 background-color: #d387ab; background-image: linear-gradient(315deg, #d387ab 0%, #b279a7 74%);
  border-radius: 4px;
  color: #FFF;
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.5);
}

.close-btn {
  margin: 20px;
  font-size: 18px;
  cursor: pointer;
}

.subscribe-wrapper {
  position: absolute;
  left: -30px;
  right: -30px;
  height: 200px;
  padding: 30px;
  background-image: url('https://i.imgur.com/MRjF1PL.png?1');
  background-position-x: 272%;
  background-position-y: -1px;
  background-repeat: no-repeat;
  background-color: #FFF;
  border-radius: 4px;
  color: #333;
  box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4);
}

.subscribe-wrapper:after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 71px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #7149c7;
}

.subscribe-wrapper h4 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 28px;
}

.subscribe-wrapper input {
  position: absolute;
  bottom: 30px;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  width: 65%;
  background: transparent;
  transition: all .25s ease;
}

.subscribe-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #a77cf4;
}

.subscribe-wrapper .submit-btn {
  position: absolute;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: #a77cf4;
  color: #FFF;
  padding: 12px 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  // letter-spacing: 2px;
  right: -10px;
  bottom: 30px;
  cursor: pointer;
  transition: all .25s ease;
  box-shadow: -5px 6px 20px 0px rgba(51, 51, 51, 0.4);
}

.subscribe-wrapper .submit-btn:hover {
  background-color: #8e62dc;
}
}
/*! rtl:end:ignore */
</style>
