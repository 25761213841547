<template>
<div class="w-100 mt-10">
  <div class="d-block md:flex justify-between">


    <div class="flex gap-2 justify-center md">
       <!-- <vs-tooltip text=" ساخت سرور جدید  ">
          <vs-button
          class="rounded text-sm md:text-base py-1 md:py-2"
          color="#1791DA" gradient-color-secondary="#0092e1" type="gradient" @click="servicePopup = !servicePopup">سرویس جدید</vs-button>

        </vs-tooltip> -->
        <!-- <vs-button type="" @click="creditPrompt = true" class="cursor-pointer bg-h-primary-light rounded text-sm md:text-base py-1 mb-2">
          افزایش اعتبار
          <b-icon class="bg-white text-black  rounded mr-2 ml-2" icon="plus" />
        </vs-button> -->
    </div>

  </div>
  <vs-card style="flex-direction: column-reverse" class="shadow-lg mr-2 p-1 lg:w-12/12 md:w-12/12 sm:w-12/12 sm:flex-col  rounded-lg shadow-sm bg-h-primary-light light-border">
    <div style="flex-direction: column" class="flex lg:flex-row sm:flex-column md:flex-column ">
      <div class="w-100 flex justify-content-start flex-col lg:ml-5 ">
        <div class="flex my-3 justify-content-between items-center ">
          <div>
            <vs-button to="/instance/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2">سرور جدید</vs-button>
            &nbsp; <a target="_blank" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary rounded text-sm md:text-base py-1 mb-2 text-white" href="https://haiocloud.com/irancloudserver">راهنمای خرید بسته ترافیکی ایران</a>

          </div>

        </div>
        <br />
        <div class="mt-5">
          <div style="width: 100%">
            <div v-if="cloudList == '' " class="text-center">
              <span class="text-lg mb-4 block">شما درحال حاضر سرویسی ندارید</span>
              <div>
                <vs-button to="/instance/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2">ساخت سرور</vs-button>
              </div>
            </div>
            <div
             v-for="cloud in cloudList"
             :key="cloud.id"
             class="row bg-h-primary mx-3 mb-2 rounded-lg p-3 items-center justify-between md:justify-center">

             <div class="flex justify-start items-center  w-full lg:w-1/3">
              <div class="flex gap-4 items-center w-10/12 lg:w-1/3">
                <div v-if="cloud.vm_status_title === 'آنلاین'">
                  <div class="bg-h-success rounded-full w-4 h-4"></div>
                </div>

                <div v-else-if="cloud.vm_status_title !== 'آنلاین'">
                  <div class="bg-h-danger rounded-full w-4 h-4"></div>
                </div>

                <div>
                  <img v-if="cloud.is_vdi != '1'" width="30" class="mr-2 "
                  :src="`data:image/png;base64,${cloud.template.template_logo_file.attachment_content}`" />
                  <img v-else width="30" class="mr-2 "
                  src="../../../../assets/images/OSimage/personal-desktop.svg" />
                  <img width="30" class="ml-2 mr-3"
                  :src="`data:image/png;base64,${cloud.location.country_flag_file.attachment_content}`" />
                </div>
              </div>

              <div class="w-1/2 text-right">
                <router-link  class="text-right text-sm md:text-lg text-white ir-yekan-bold cursor-pointer"  :to="`/apps/viracloud/${cloud.id}`" >{{cloud.vm_title}}</router-link>
                <div class="text-right text-xs md:text-sm font-light">{{ cloud.template.title }} {{cloud.template_versin}} </div>
              </div>

            </div>
                <div class="flex items-center mt-3 md:mt-0 w-full md:w-1/3 justify-center"
                v-if="cloud.vm_status_title !== 'در حال آماده سازی'"        >
                  <div
                    v-if="cloud.is_vdi == '0' && cloud.vm_status_title === 'آنلاین'"
                    :id="'power' + cloud.id"
                    title="خاموش کردن"
                    class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
                    @click="shutdownPrompt = true, id = cloud.id">
                    <b-tooltip variant="info" :target="'power' + cloud.id" triggers="hover">
                      خاموش کردن
                    </b-tooltip>
                      <b-icon class="my-2" scale="2" icon="power"></b-icon>
                  </div>
                  <div
                    v-if="cloud.is_vdi == '1' && cloud.vm_status_title === 'آنلاین'"
                    :id="'power' + cloud.id"
                    title="خاموش کردن"
                    class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
                    @click="shutdownPrompt = true, id = cloud.id, vdi = true">
                    <b-tooltip variant="info" :target="'power' + cloud.id" triggers="hover">
                      خاموش کردن
                    </b-tooltip>
                      <b-icon class="my-2" scale="2" icon="power"></b-icon>
                  </div>
                  <div
                    v-if="cloud.is_vdi == '0' && cloud.vm_status_title === 'خاموش'"
                    id="poweron"
                    title="روشن کردن"
                    class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
                    @click="popupActiveReset = true, id = cloud.id">
                    <b-tooltip variant="info" target="poweron" triggers="hover">
                      روشن کردن
                    </b-tooltip>
                      <b-icon class="my-2" scale="2" icon="power"></b-icon>
                  </div>
                  <div
                  v-if="cloud.is_vdi == '0' && cloud.vm_status_title !== 'خاموش'"
                    :id="'reboot' + cloud.id"
                    title="خاموش کردن"
                    class="hover:shadow-xl  m-2 p-2   hover:text-white cursor-pointer"
                    @click="popupActiveReset = true, id = cloud.id">
                    <b-icon class="my-2" scale="2" icon="arrow-clockwise" />
                    <b-tooltip variant="info" :target="'reboot' + cloud.id" triggers="hover">
                      ریبوت
                    </b-tooltip>
                  </div>
                  <div
                  v-if="cloud.is_vdi == '1' && cloud.vm_status_title == 'خاموش'"
                    :id="'reboot' + cloud.id"
                    title="خاموش کردن"
                    class="hover:shadow-xl  m-2 p-2   hover:text-white cursor-pointer"
                    @click="popupActiveReset = true, id = cloud.id, vdi = true">
                    <b-icon class="my-2" scale="2" icon="arrow-clockwise" />
                    <b-tooltip variant="info" :target="'reboot' + cloud.id" triggers="hover">
                      ریبوت
                    </b-tooltip>
                  </div>
                  <div
                  :id="'delete' + cloud.id"
                    @click="setDeletePrompt (cloud.id), deletePrompt = true, id = cloud.id"
                    class="hover:shadow-xl m-2 p-2  hover:text-white cursor-pointer">
                    <b-tooltip variant="info" :target="'delete' + cloud.id" triggers="hover">
                      حذف سرور
                    </b-tooltip>
                    <b-icon class="my-2" scale="2" icon="trash"></b-icon>
                </div>

              </div>
              <div v-else id="load">
                <b-tooltip variant="info" target="load" triggers="hover">
                  {{getFlag(cloud.vm_status)}}
                  در حال ساخت
                </b-tooltip>
                <b-icon
                  class="h1 text-dark"
                  icon="gear"
                  animation="spin"
                ></b-icon>
              </div>

                <div class="flex flex-wrap justify-center lg:justify-end md:mt-4 lg:mt-0 items-center gap-4 w-full md:w-full lg:w-1/3">

                  <div v-if="cloud.is_vdi == '0'">
                    <div class=" bg-h-primary-light rounded p-2 text-center ">
                      <b-button v-if="cloud.sale_mode" class="bg-h-success text-primary text-xs btn-xs border-dashed">سرور حراجی</b-button>

                    <span v-if="cloud.sale_mode != 1" @click="copyToClipboard(cloud.vm_master_ip)" class="text-white text-sm md:text-lg cursor-pointer"> {{ cloud.vm_master_ip }}</span>
                    <span v-else @click="copyToClipboard(cloud.proxy_domain.toLowerCase())" class="text-white text-sm md:text-lg cursor-pointer"> {{ cloud.proxy_domain.toLowerCase() }}</span>


                    <span v-if="cloud.sale_mode != 1" class="text-grey font-light text-xs block">آیپی سرور</span>
                    <span v-else class="text-grey font-light text-xs block"></span>

                    </div>
                  </div>
                  <div v-else-if="cloud.vm_status_title === 'آنلاین'">
                    <div class=" bg-h-info rounded p-2 py-4 text-center ">
                    <span @click="getVDILink(cloud.id), LoginToVDIPopup = !LoginToVDIPopup" class="text-white text-sm md:text-base cursor-pointer"> ورود به دسکتاپ</span>

                    </div>
                  </div>

                  <div class=" bg-h-primary-light rounded p-2 text-center w-24 cursor-pointer">
                    <span @click="copyToClipboard(cloud.vm_sequence_id)" class="text-white text-sm md:text-lg d-block"> {{ cloud.vm_sequence_id }}</span>
                    <span class="text-grey font-light text-xs">شماره ماشین</span>
                  </div>
                  <div v-if="cloud.is_vdi == '0' && cloud.vm_ip6 != ''" class="">
                    <div class=" bg-h-primary-light rounded p-2 text-center cursor-pointer ">
                    <span @click="copyToClipboard(cloud.vm_ip6)" class="text-white text-sm md:text-lg d-block"> IPv6</span>
                    <span class="text-grey font-light text-xs">برای کپی کلیک کنید</span>
                    </div>
                  </div>
                  <router-link
                    :id="'details' + cloud.id"
                    class="hover:shadow-xl m-1 text-grey cursor-pointer"
                    :to="`/apps/viracloud/${cloud.id}`">
                    <b-tooltip variant="info" :target="'details' + cloud.id" triggers="hover">
                      مشخصات سرور
                    </b-tooltip>
                      <b-icon class="my-2" scale="2" icon="arrow-left-square" />
                  </router-link>

                </div>
            </div>


          </div>
          <vs-pagination
          class="center w-full"
          :total="countPage"
          v-model="pageNumber"
        />
        </div>
      </div>
    </div>

    <!-- New Service -->
    <!-- Reboot VM -->
    <vs-popup class="holamundo"  title="ری استارت کردن سرور" :active.sync="popupActiveReset">
      <div class="flex justify-content-center items-center flex-col">
        <div>
          <img width="200" src="../../../../assets/images/elements/cloud-outline.webp" alt="">
        </div>
        <div>
          آیا از ری استارت کردن سرور خود مطمئن هستید ؟
        </div>
        <div class="flex justify-content-between mt-4">
          <vs-button color="primary" @click="setRebootPrompt(id)" class="rounded">بله </vs-button>
        </div>
      </div>
    </vs-popup>
    <!-- ShutDown VM -->
    <vs-popup :active.sync="shutdownPrompt" title=" خاموش کردن">
        <div class="p-2">
          <h5>آیا مایل به خاموش کردن ابر هستید؟</h5>
          <vs-button icon="power" class="rounded float-right" @click="turnOff(id)"
            >خاموش کردن</vs-button
          >
        </div>
      </vs-popup>
    <!-- delete vira cloud prompt -->
    <div>
      <vs-popup :active.sync="deletePrompt" title="حذف  ابر">
        <div class="con-exemple-prompt">
          <div v-if="smsConfirmStatus === 1">
            <p>
              کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.
            </p>
            <vs-input
              class="w-full mb-3"
              v-model="requestCode"
              label-placeholder="کد تایید"
            ></vs-input>
          </div>
          <div v-else>
            <p>آیا از حذف اطمینان دارید. ؟</p>
            <span class="text-danger">نکته مهم:</span> <span>حداقل هزینه جهت ساخت مجدد سرور 20 هزار تومان است، دقت داشته باشید بعد از حذف سرور باید حداقل شارژ را برای ساخت مجدد داشته باشید.</span>
          </div>
          <vs-button
          icon="delete"
          class="float-right rounded w-25 mt-4"
          color="danger"
          @click="deleteRecord(id)"
          >تایید حذف</vs-button>
          <vs-button
            v-if="smsConfirmStatus === 1"
            icon=""
            class=" rounded mt-4 ml-2"
            color="secondary"
            to="pages/user-settings"
          >غیرفعال سازی دریافت پیامک</vs-button>
        </div>
      </vs-popup>
    </div><!-- rebbot vm prompt -->
    <vs-popup title="ورود به دسکتاپ مجازی" :active.sync="LoginToVDIPopup">
      <span class="block text-center">
        برای ورود به دسکتاپ مجازی ابتدا باید نرم افزار Virt Viewer را نصب داشته باشید.
      </span>
      <div class="flex gap-2 justify-center mt-6">
        <vs-button :href="VDILink" class="rounded bg-h-secondary"> دانلود گواهی ورود </vs-button>
        <vs-button href="https://www.haio.ir/app/uploads/2023/04/virt-viewer-x64-11.0-1.0.zip" target="_blank" class="rounded"> دانلود Virt Viewer </vs-button>
      </div>
    </vs-popup>
  </vs-card>
      <!--  prompt area-->
      <vs-prompt
      class="w-full"
      color="#0092e1"
      title="افزایش شارژ"
      @accept="acceptAlert"
      accept-text="پرداخت"
      cancel-text="انصراف"
      :active.sync="creditPrompt">
      <div class="con-exemple-prompt">
        <vs-input-number color="#0092e1" min="80000"  v-model="amountCharge"  step="10000"/>
        <p class="text-center">تومان</p>
        <vs-alert :active="true" color="#0092e1" icon="new_releases" >
          <span class="text-white"> مبلغ قابل پرداخت با احتساب 10 درصد مالیات ارزش افرزوده <b class="text-success">{{realPrice | money}}</b> تومان</span>
        </vs-alert>
        <!-- <vs-alert v-if="amountCharge >= 1000000" :active="true" color="#28C76E" icon="new_releases" class="mt-4">
          <span class="text-white"> هوراا! مبلغ وارد شده شامل هدیه هایو می شود با شارژ این مبلغ <b class="text-success">{{giftedAmount | money}}</b> سکه هدیه دریافت کنید.</span>
        </vs-alert> -->

        <div class="mt-5 mb-2 flex w-full items-center justify-between">

          <div v-if="encteCode" class="flex w-7/12  justify-center items-center">
            <vs-input size="small" class="ml-2 w-7Z/12" v-model="offCode" />
            <vs-button @click="confirmOffCode" size="small" color="#0092e1">اعمال</vs-button>
          </div>

        </div>
        <div style="margin-top: 30px">
          <vs-alert :active="true" color="#090c2b" icon="new_releases" style="height:max-content">
            <span class="text-h-warning">مهم</span>
            <span class="text-white text-justify"> قبل از شارژ از موجود بودن ظرفیت سرور ها از طریق صفحه ساخت سرور اطمینان حاصل کنید، عودت وجه درصورت شارژ اشتباه وجود ندارد.</span>
          </vs-alert>
          <vs-alert :active="true" color="#090c2b" icon="new_releases" style="height:max-content">
            <span class="text-h-warning">توجه</span>
            <span class="text-white text-justify"> حداقل شارژ 80 هزار تومان و حداقل مبلغ برای ساخت سرور 20 هزار تومان است.</span>
          </vs-alert>
        </div>
        <div v-if="offValueCalculate" class="mt-5 flex items-center justify-center ">
          <span class="text-sm font-medium m-2">
            افزایش اعتبار هایو بعد از پرداخت:
          </span>
          <vs-chip class="dana-font shadow-md m-2 animate-bounce" transparent color="success">
            <vs-avatar color="#0092e1" text="هایو"/>
            <span class="dana-font text-black ">
              {{ offValueCalculate }}
            </span>
          </vs-chip>
        </div>
        <br />
      </div>
    </vs-prompt>

    <!--end  prompt area-->
</div>
</template>

<script>
// import FloatIp from '../../apps/haiocloud/viracloud/viradetails/FloatIp.vue'
import CountTo from 'vue-count-to'

export default {
  name: 'FastAccess',
  components: {

    CountTo
  },
  data () {
    return {
      startVal: 1,
      verifyoffCode:'',
      amountCharge : 80000,
      offPercent:'',
      offValueCalculate: '',
      encteCode: false,
      offCode:'',
      popupActiveReset: false,
      shutdownPrompt: false,
      pageNumber: 0,
      cloudSelected: {},
      search: '',
      deletePrompt: false,
      sumCal: '',
      priceAmount: '',
      loading:false,
      ipPrompt:false,
      activePrompt : false,
      creditPrompt : false,
      selectedID: 0,
      cloudList: [],
      flag: '',
      countPage: 1,
      serverIdreboot: null,
      servicePopup: false,
      serverType: '',
      VDILink:'',
      vdi:false,
      LoginToVDIPopup: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    realPrice () {
      return ((this.amountCharge * 1) + (0.10 * this.amountCharge))
    },
    giftedAmount () {
      if (this.amountCharge >= 1000000 && this.amountCharge <= 1999999) {
        return (this.amountCharge * 10 / 100)
      }
      if (this.amountCharge > 1999999 && this.amountCharge <= 2999999) {
        return (this.amountCharge * 15 / 100)
      }
      if (this.amountCharge > 2999999 && this.amountCharge <= 3999999) {
        return (this.amountCharge * 20 / 100)
      }
      if (this.amountCharge > 3999999 && this.amountCharge <= 5999999) {
        return (this.amountCharge * 25 / 100)
      }
      if (this.amountCharge > 5999999 && this.amountCharge <= 9999999) {
        return (this.amountCharge * 30 / 100)
      }
      if (this.amountCharge > 9999999 && this.amountCharge <= 19999999) {
        return (this.amountCharge * 35 / 100)
      }
      if (this.amountCharge > 19999999 && this.amountCharge <= 29999999) {
        return (this.amountCharge * 40 / 100)
      }
      if (this.amountCharge >= 30000000) {
        return (this.amountCharge * 50 / 100)
      }
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    smsConfirmStatus () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    pageNumber () {
      this.getCloudList()
    },
    $route () {
      this.getCloudList()
    },
    search () {
      this.getCloudList()
    }
  },
  methods: {
    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    acceptAlert () {
      // const authtoken = localStorage.getItem('accessToken')
      this.$http.post('https://api.haio.ir/v1/invoice/charge/add', {
        amount: this.amountCharge,
        off_code: this.verifyoffCode,
        payment_side:'2',
        redirect_url: 'https://console.haio.ir/pages/payment'
      })
        .then(async (res) => {
          window.location = (res.data.params.payment_url)
          // console.log("Set follow txt record at your dns server:")
          // console.log(res.data.params)
        })
        .catch(error => {
          this.$vs.notify({
            color:'danger',
            title:' شارژ حساب',
            text:error.response.data.message
          })

        })
    },
    deleteRequestCode (vmId)   {
      this.$http
        .get(`/cloud/vm/${vmId}/delete/request`)
        .then((res) => {
          this.$vs.notify({
            color: 'success',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
    },
    deleteRecord (id) {
      this.$http
        .post(`/cloud/vm/${id}/delete`, {
          confirm_code: this.requestCode
        })
        .then((res) => {
          this.getCloudList()
          this.$vs.notify({
            color: 'warning',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
        .finally(() => {
          this.getCloudList()
          this.deletePrompt = false
        })
    },
    handleResetPropmt (item) {
      this.popupActiveReset = true
      this.serverIdreboot = item
    },
    setIpPrompt (id) {
      this.activePrompt = false
      this.ipPrompt = true
      this.cloudSelected = id
    },
    setIpPromtModal () {
      this.activePrompt = false
      this.ipPrompt = true
      this.cloudList.map((item) => {
        if (item.id === this.selectedID) {
          this.cloudSelected = item
        }
      })
    },
    setDeletePrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true
      if (this.smsConfirmStatus === 1) {
        this.deleteRequestCode(d)
      }
    },

    getConsume () {
      this.loading = false
      this.$http.get('user/consume').then(res => {
        let pricer = 0
        Object.values(res.data.params).forEach((items) => {
          pricer += Object.values(items)[0]
        })
        /// checker
        setTimeout(() => {
          this.priceAmount = pricer
        }, 1000)
        this.$store.dispatch('PushDataConsume', res.data.params)
        // res.data.params.map((item) => {
        //   this.dataLabel = this.dataLabel.concat(Object.keys(item).toString())
        //   this.dataValue = this.dataValue.concat(Object.values(item))
        // })
        setTimeout(() => {
          res.data.params.forEach((int) => {
            this.sumCal += Object.values(int)[0]
          })
        }, 500)
        this.loading = true
        this.$store.dispatch('PushDataChart', this.dataLabel)
        this.dataStore = JSON.stringify(this.$store.state.DataChart)
      }).catch(() => {
      })
    },
    setRebootPrompt (d) {
      this.reboot(d)
      this.popupActiveReset = false
    },

    turnOff (id) {

      if (!this.vdi) {
        this.$http
          .get(`/cloud/vm/${id}/shutdown`)
          .then((res) => {
            this.shutdownPrompt = false
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })

          })
      } else {
        this.$http
          .get(`/cloud/vm/${id}/shutdown`)
          .then((res) => {
            this.shutdownPrompt = false
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })

          })
      }
    },
    gotoDetailsPage () {
      this.$router.push(`/apps/viracloud/${this.selectedID}`)
    },
    reboot (id) {
      this.rebootPrompt = false
      if (!this.vdi) {
        this.$http
          .get(`/cloud/vm/${id}/reboot`)
          .then((res) => {
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })
          })
      } else {
        this.$http
          .get(`/cloud/vm/${id}/reboot`)
          .then((res) => {
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })
          })
      }
    },
    getCloudList () {
      this.$vs.loading()
      this.$http
        .get(
          `/cloud/vm/?page=${this.pageNumber}&search=${this.search}&sale_mode=0`
        )
        .then((res) => {
          this.$vs.loading.close()
          const d = res.data.params
          this.countPage = d.last_page
          this.cloudList = d.data
        })
        .catch((err) => {
          this.$vs.loading.close()
          if (err.response.status === 404) {
            this.cloudList = []
            return
          }

          this.$vs.notify({
            color: 'danger',
            title: ' ویرا کلود',
            text: err.response.data.message
          })
        })
    },
    getFlag (status) {
      if (status === 1) {
        this.repeat = setTimeout(() => {
          this.getCloudList()
        }, 60000)
      }
    },
    getVDILink (id) {
      this.$http.get(`/cloud/vm/vdi/${id}`)
        .then((response) => {
          this.VDILink = response.data.params.url
        })
    }

  },

  async mounted () {
    await this.getCloudList()
    // await this.getVpc()
    await this.getFlag()
  },
  destroyed () {
    window.clearInterval(this.repeat)
  }
}
</script>

<style scoped>
.bg-box {
  background-color: #e9f1fa !important;
}
.white-light {
  background-color: #ffffff !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #98befb);
}
.hoverItem:hover {
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}


</style>
